export default {
  usersData: (state) => state.usersData || null,
  promoData: (state) => state.promoData || null,
  adminPanelUsersPageSize: (state) => state.adminPanelUsersPageSize,
  adminPanelUsersTotal: (state) => state.adminPanelUsersTotal,
  adminFilterValue: (state) => state.adminFilterValue,
  adminFilterClearTrigger: (state) => state.adminFilterClearTrigger,
  adminModalPromoOpen: (state) => state.createNewPromoModalOpenTrigger,
  activePromo: (state) => state.activePromo,
  isEditMode: (state) => state.isEditMode,
  paymentHistoryReloadTrigger: (state) => state.paymentHistoryReloadTrigger,
  redeemPromoLoadingState: (state) => state.redeemPromoLoadingState,
  confirmModalRedeemTrigger: (state) => state.confirmModalRedeemTrigger,
  amountOfTokens: (state) => state.amountOfTokens,
  adminPanelPromoPageSize: (state) => state.adminPanelPromoPageSize,
  adminPanelPromoTotal: (state) => state.adminPanelPromoTotal,
}