import apiService from '@/utils/api-client'
import config from "@/utils/appConfig";

export default {
  emailSubscribe(context, { email }) {
    return apiService.post(
      `subscriptions`,
      { email },
      { baseURL: config.VUE_APP_API_USERS_URL },
    )
    .then(response => {
      if(response.success) {
        return response.success
      }
    })
  },
}
