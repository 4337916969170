<template>
  <div v-on-clickaway="closeSelect">
    <slot name="label">
      <gl-menu-item
        :disabled="disabled"
        :icon="icon"
        :icon-after="iconAfter"
        :icon-after-height="iconAfterHeight"
        :icon-after-width="iconAfterWidth"
        :icon-before-height="iconBeforeHeight"
        :icon-before-width="iconBeforeWidth"
        :icon-height="iconHeight"
        :icon-width="iconWidth"
        :label="label"
        @click="toggleOpen"
      />
    </slot>
    <div
      v-if="isOpen"
      class="gl-menu-dropdown"
      :class="{ 'gl-menu-dropdown__padder': padder }"
      @click="closeSelectItem"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import GlMenuItem from '@/components/gl-menu-item'

export default {
  components: {
    GlMenuItem,
  },
  mixins: [clickaway],
  props: {
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: null,
    },
    iconBeforeHeight: {
      type: Number,
      default: 16,
    },
    iconAfterHeight: {
      type: Number,
      default: 16,
    },
    iconBeforeWidth: {
      type: Number,
      default: 16,
    },
    iconAfterWidth: {
      type: Number,
      default: 16,
    },
    iconHeight: {
      type: Number,
      default: 16,
    },
    iconWidth: {
      type: Number,
      default: 16,
    },
    iconAfter: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    padder: {
      type: Boolean,
      default: false,
    },
    closeOnClickItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    closeSelect() {
      this.isOpen = false
    },
    closeSelectItem() {
      if (this.closeOnClickItem) {
        this.closeSelect()
      }
    },
    toggleOpen() {
      this.isOpen = !this.isOpen
      this.$emit('toggle', this.isOpen)
    },
  },
}
</script>
