import apiService from '@/utils/api-client'
import config from '@/utils/appConfig'
import router from '@/router'

export default {
  // eslint-disable-next-line no-unused-vars
  login({ commit }, { email, password, token }) {
    const headers = token ? { 'google-auth-token': token } : {}

    try {
      return apiService.post(
        'users/login',
        { email, password },
        { 
          baseURL: config.VUE_APP_API_USERS_URL,
          headers: headers,
        },
      )
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  setScoringFormula({ commit }, { scoringFormula }) {
    try {
      return apiService.post('users/scoring-formula', { scoringFormula })
    } catch (error) {
      console.error(error)
    }
  },
  logout({ commit }) {
    localStorage.removeItem('to.fullPath')
    localStorage.removeItem('lastRoute')
    commit('REMOVE_USER_TOKEN')
    commit('REMOVE_USER_ID')
  },
  register({ commit }, { email, password, username, token }) {
    const headers = token ? { 'google-auth-token': token } : {};

    try {
      return apiService
        .post(
          'users/register',
          { email, password, username },
          { 
            baseURL: config.VUE_APP_API_USERS_URL, 
            headers: headers, 
          },
        )
        .then(({ data }) => {
          commit('SET_USER_TOKEN', data)
          commit('SET_USER_ID', data)
        })
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  resendConfirmation({ commit }, { email }) {
    try {
      return apiService.post(
        'users/resend-confirmation',
        { email },
        { baseURL: config.VUE_APP_API_USERS_URL },
      )
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  confirmEmail({ commit }, { email, verificationCode }) {
    try {
      return apiService.post(
        'users/confirm',
        { email, verificationCode },
        { baseURL: config.VUE_APP_API_USERS_URL },
      )
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  forgotPassword({ commit }, { email }) {
    return apiService.post(
      'users/forgot-password',
      { email },
      { baseURL: config.VUE_APP_API_USERS_URL },
    )
  },
  // eslint-disable-next-line no-unused-vars
  resetPassword({ commit }, { email, password, verificationCode }) {
    return apiService.post(
      'users/reset-password',
      { email, password, verificationCode },
      { baseURL: config.VUE_APP_API_USERS_URL },
    )
  },
  getMe({ commit }) {
    return apiService
      .get(`users/me`, { baseURL: config.VUE_APP_API_USERS_URL })
      .then(({ data }) => {
        commit('SET_USER_DATA', data)
      })
      .catch(err => {
        console.log(err)
      })
  },
  resetToken({ commit }, { refreshToken }) {
    return apiService
      .get(`users/reset-token/${refreshToken}`, {
        baseURL: config.VUE_APP_API_USERS_URL,
      })
      .then(({ data }) => {
        if (data && data.token) {
          commit('SET_USER_TOKEN', data)
          commit('SET_USER_REFRESH_TOKEN', data)
          commit('SET_USER_ID', data)
          return true
        } else {
          if (router.currentRoute.name !== 'login') {
            localStorage.setItem('lastRoute', router.currentRoute.name)
          }
          this.logout()
          window.location.assign('/login')
        }
      })
      .catch(err => {
        console.log(err)
      })
  },
  initToken({ commit }) {
    const token = localStorage.getItem('token')
    if (token) commit('SET_USER_TOKEN', { token })
    return token
  },
  // eslint-disable-next-line no-unused-vars
  changeEmail({ commit }, { newEmail, password }) {
    return apiService.post('users/change-email', { newEmail, password })
  },
  // eslint-disable-next-line no-unused-vars
  verifyEmail({ commit }, { token }) {
    return apiService.post(
      `users/verify-email?token=${encodeURIComponent(token)}`,
    )
  },
  // eslint-disable-next-line no-unused-vars
  changePassword({ commit }, { password, newPassword }) {
    return apiService.post('users/change-password', { password, newPassword })
  },
}
