import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
import configApp from '@/utils/appConfig'
import router from '@/router'

const apiClient = axios.create()

let cancelTokenSource = axios.CancelToken.source() // Variable to hold the cancel token source

apiClient.interceptors.request.use(
  config => {
    // Generate a unique request key based on the request URL and method
    const requestKey = `${config.url}_${config.method}_${JSON.stringify(
      config.params,
    )}`

    // Cancel previous request (if any) with the same key
    if (
      cancelTokenSource &&
      cancelTokenSource[requestKey] &&
      router.currentRoute.name === 'analytics'
    ) {
      cancelTokenSource[requestKey].cancel('Request canceled')
    }

    cancelTokenSource[requestKey] = axios.CancelToken.source() // Create a new cancel token source for the request
    config.cancelToken = cancelTokenSource[requestKey].token // Set the cancel token on the request config

    config.baseURL = config.baseURL || configApp.VUE_APP_API_BASE_URL
    
    const token = localStorage.getItem('token')
    if (token) config.headers['Authorization'] = `Bearer ${token}`
    return config
  },
  error => error,
)

let refreshTokenPromise

apiClient.interceptors.response.use(
  res => {
    if (
      res?.config?.url === 'users/me' &&
      !res?.data?.success &&
      router.currentRoute?.meta?.requiresAuth
    ) {
      Vue.prototype.$showPrimeToast(
        'error',
        'Error Message',
        `${res?.data?.data?.message}`,
      )
      localStorage.removeItem('token')
      window.location.assign('/source-of-funds')
    }
    if (res?.data?.data?.status === 422) {
      localStorage.setItem('lastRoute', router.currentRoute.name)
    }
    if (res?.data?.data?.status === 501) {
      Vue.prototype.$showPrimeToast(
        'info',
        'Info Message',
        `This feature has not been implemented yet for selected blockchain - ${store.state.analytics.coinData.label}`,
      )
      // throw new Error('Not implemented')
    }

    if (res.data && !res.data.status && res.data.data === null) {
      Vue.prototype.$showPrimeToast(
        'error',
        'Error Message',
        'API REQUEST ERROR',
      )
      throw new Error('API REQUEST ERROR')
    }
    // Handle response data
    return res.data
  },
  error => {
    if (error?.response?.status === 401) {
      const token = localStorage.getItem('token')
      const refreshToken = localStorage.getItem('refreshToken')
      localStorage.setItem('lastRoute', router.currentRoute.name)
      localStorage.removeItem('token')

      if (refreshToken && refreshToken !== 'undefined' && token) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = store
            .dispatch('user/resetToken', { refreshToken })
            .then(() => {
              refreshTokenPromise = undefined

              const fullPath =
                localStorage.getItem('to.fullPath') || router.currentRoute.name

              return router.push(fullPath)
            })
            .catch(() => {
              window.location.assign('/login')
            })
        }
      } else {
        if (!router.currentRoute?.meta?.requiresAuth) return
        window.location.assign('/login')
      }
      throw error
    }

    Vue.prototype.$showPrimeToast(
      'error',
      'Error Message',
      error?.response?.data?.data?.message || error,
    )
    // Handle response error
    throw error
  },
)

export function cancelRequest(requestKey) {
  if (cancelTokenSource && cancelTokenSource[requestKey]) {
    cancelTokenSource[requestKey].cancel('Request canceled')
    delete cancelTokenSource[requestKey]
  }
}

export default apiClient
