import router from '@/router'
import analyticsState from '@/store/modules/analytics/state'

export const goToEntity = (id, type = 'view') => {
  if (id) {
    const routeData = router.resolve({ path: `entity-explorer/${type}/${id}` })
    window.open(routeData.href, '_blank')
  }
}

export const goMailto = link => {
  if (!link) return
  window.location.href = `mailto:${link}`
}

export const contactSatoshieyeSupport = () => {
  window.location.href = 'mailto:support@satoshieye.com'
}

export const linkDataFormatter = (val, coin, type = 'address') => {
  const explorerData = analyticsState.currencyList.find(el => el.key === coin)

  return {
    link:
      type === 'tx' || type === 'transaction'
        ? explorerData.explorerTx
            .replace('{VALUE}', val)
            .replace('{TYPE}', type)
        : explorerData.explorerAddr
            .replace('{VALUE}', val)
            .replace('{TYPE}', type) || '',
  }
}

export const windowOpenLink = (link, target = '_blank') => {
  if (!link) return
  window.open(link, target)
}
