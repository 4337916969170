<template>
  <header
    v-if="$route.name"
    class="header-wrapper"
    :class="{
      'header-wrapper-with-banner': withBanner,
      'header-shadow': isShadowHeader,
    }"
  >
    <div
      v-if="$route.name === 'landing-page'"
      class="header"
    >
      <div>
        <SatoshiEyeLogo
          class="header-logo pointer"
          @click="$router.push({ name: 'landing-page' })"
        />
      </div>
      <div class="flex-1 flex flex-end m-display-none">
        <div
          class="flex-1 flex align-center space-between bold gap-4 max-w-600 fs-18 t-fs-14"
        >
          <div
            class="pointer"
            @click="
              scrollToSelectorItem('.main', '.landing-page-features-wrapper')
            "
          >
            Features
          </div>
          <div
            class="pointer"
            @click="
              scrollToSelectorItem(
                '.main',
                '.landing-page-pricing__for-background',
              )
            "
          >
            Pricing
          </div>
          <div
            class="pointer"
            @click="scrollToSelectorItem('.main', '.landing-page-faq-wrapper')"
          >
            FAQ
          </div>
          <div
            v-if="!isLogin"
            class="pointer"
            @click="$router.push('/login')"
          >
            Log In
          </div>
          <router-link
            class="header__button-get-report"
            :to="'/source-of-funds'"
          >
            Get Report
          </router-link>
        </div>
      </div>
      <div class="display-none m-flex m-flex-end m-fullwidth">
        <div>
          <transition-group name="fade-leave">
            <gl-icon
              v-if="showLandingMenu"
              key="close"
              class="mr-2 pointer"
              :height="24"
              name="close"
              :width="24"
              @click="closeFooterLandingMenu"
            />
            <gl-icon
              v-else
              key="mobile-menu"
              class="mr-2 pointer"
              :height="34"
              name="mobile-menu"
              :width="34"
              @click="openFooterLandingMenu"
            />
          </transition-group>
        </div>
      </div>
    </div>
    <div
      v-else
      class="header header-main gap-4"
    >
      <div class="flex align-center space-between wrap">
        <div
          v-if="isMobile && $route.name === 'report'"
          class="header-back-arrow-wrapper bold accent-text-link pointer"
          @click="$router.push({ name: 'source-of-funds' })"
        >
          <gl-icon
            class="mr-2"
            :height="18"
            name="arrow-back"
            :width="11"
          />
          <div> Back to <span class="bold-700">Source of Funds</span> </div>
        </div>
        <div v-else>
          <SatoshiEyeLogo
            class="header-logo-main mr-4"
            @click="$router.push({ name: 'landing-page' })"
          />
        </div>
        <div
          v-if="!isMobile"
          class="flex-1 flex align-center space-around gap-4 pointer bold"
        >
          <div class="ml-4">
            <router-link
              class="header__nav-link"
              :to="{ name: 'source-of-funds' }"
            >
              Source of funds
            </router-link>
          </div>
          <!-- <div class="ml-4">
            <router-link
              class="header__nav-link header__nav-link-disabled"
              :to="{ name: 'landing-page' }"
            >
              Destination of funds
            </router-link>
          </div> -->

          <!-- <div> Destination of funds </div> -->
        </div>
      </div>

      <div class="flex-1 flex align-center flex-end gap-4">
        <div
          v-if="userEmail && (!isMobile || $route.name !== 'payment')"
          class="flex m-fs-25"
          @click="$router.push({ name: 'payment' })"
        >
          <GlActionLink
            class="mr-2"
            :link-text="
              isMobile
                ? `${userData.balance || 0}`
                : `${userData.balance || 0} Lookups`
            "
            router
          />
          <gl-icon
            class="pointer"
            :height="28"
            name="lookups-blue"
            :width="28"
          />
        </div>
        <div
          v-if="userEmail && isDesktop"
          class="flex align-center"
        >
          <gl-menu-dropdown
            close-on-click-item
            icon="arrow-down"
            :icon-before-height="8"
            :icon-before-width="18"
            :label="userData.email"
          >
            <router-link
              v-if="isAdmin"
              class="flex align-center main-text py-2"
              :to="{name: 'admin-panel'}"
            >
              <gl-icon
                class="mr-2"
                :height="24"
                name="your-reports"
                :width="24"
              />
              Admin panel
            </router-link>
            <router-link
              class="flex align-center main-text py-2"
              :to="{name: 'profile'}"
            >
              <gl-icon
                class="mr-2"
                :height="24"
                name="unknown-user-transparent"
                :width="24"
              />
              Profile Information
            </router-link>
            <router-link
              class="flex align-center main-text py-2"
              :to="{name: 'your-reports'}"
            >
              <gl-icon
                class="mr-2"
                :height="24"
                name="your-reports"
                :width="24"
              />
              Your Reports
            </router-link>

            <div
              class="flex align-center py-2"
              @click="logoutUser"
            >
              <gl-icon
                class="mr-2"
                :height="24"
                name="logout"
                :width="24"
              />
              Log Out
            </div>
          </gl-menu-dropdown>
          <Avatar
            class="ml-1 mr-1"
            :label="userFirstLetter"
            shape="circle"
            style="background-color: #194bfb; color: #ffffff"
          />
        </div>

        <div
          v-if="!isDesktop && $route.name === 'payment'"
          class="header-back-arrow-wrapper bold accent-text-link pointer"
          @click="SET_IS_PAYMENT_HISTORY_OPEN(!isPaymentHistoryOpen)"
        >
          <transition-group name="fade-leave">
            <gl-icon
              v-if="!isPaymentHistoryOpen"
              key="history-time"
              class="mr-2"
              :height="29"
              name="history-time"
              :width="29"
            />
            <gl-icon
              v-else
              key="close"
              class="mr-2"
              :height="24"
              name="close"
              :width="24"
            />
          </transition-group>
        </div>

        <div
          v-else
          class="flex pointer"
          @click="contactSupport"
        >
          <gl-icon
            class="mr-2"
            dark
            :height="isMobile ? 30 : 24"
            name="get-help"
            :width="isMobile ? 30 : 24"
          />
          <GlActionLink
            v-if="!isMobile"
            class="mr-2"
            link-text="Get help?"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'
import SatoshiEyeLogo from '@/assets/svg/header/SatoshiEyeLogo.svg?inline'
import GlActionLink from '@/components/gl-action-link'
import GlMenuDropdown from '@/components/gl-menu-dropdown'
import Button from 'primevue/button'
import Avatar from 'primevue/avatar'
// Vuex
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
// Utils
import { scrollToSelector } from '@/utils/scroll-to'
import { contactSatoshieyeSupport } from '@/utils/go-to-route'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    Button,
    GlIcon,
    GlActionLink,
    SatoshiEyeLogo,
    GlMenuDropdown,
    Avatar,
  },
  mixins: [deviceWidthMixin],
  props: {
    withBanner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headerShadowRouteNames: ['report', 'landing-page'],
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('sidebar', ['showLandingMenu', 'isPaymentHistoryOpen']),
    ...mapGetters('user', ['isAdmin', 'isLogin']),
    headerHeight() {
      return getComputedStyle(document.documentElement).getPropertyValue(
        '--header-height',
      )
    },
    reportBannerHeight() {
      return getComputedStyle(document.documentElement).getPropertyValue(
        '--report-banner-height',
      )
    },
    userFirstLetter() {
      return this.userEmail && this.userEmail.charAt(0).toUpperCase()
    },
    userEmail() {
      return this.userData && this.userData.email
    },
    isShadowHeader() {
      return !!this.headerShadowRouteNames.find(r => r === this.$route.name)
    },
  },
  watch: {
    $route() {
      this.closeFooterLandingMenu()
      this.SET_IS_PAYMENT_HISTORY_OPEN(false)
    },
  },
  methods: {
    ...mapActions('user', ['logout']),
    ...mapMutations({
      SET_SHOW_LANDING_MENU: 'sidebar/SET_SHOW_LANDING_MENU',
      SET_IS_PAYMENT_HISTORY_OPEN: 'sidebar/SET_IS_PAYMENT_HISTORY_OPEN',
    }),
    scrollToSelector,
    contactSatoshieyeSupport,
    scrollToSelectorItem(wrap, aim) {
      this.closeFooterLandingMenu()
      this.scrollToSelector(
        wrap,
        aim,
        parseInt(this.headerHeight) + parseInt(this.reportBannerHeight),
      )
    },
    closeDropdown() {
      this.isOpen = false
    },
    logoutUser() {
      this.logout()
      window.location.assign('/')
    },
    contactSupport() {
      this.contactSatoshieyeSupport()
    },
    closeFooterLandingMenu() {
      this.SET_SHOW_LANDING_MENU(false)
    },
    openFooterLandingMenu() {
      this.SET_SHOW_LANDING_MENU(true)
    },
  },
}
</script>

<style>
.admin-mark {
  position: absolute;
  left: 95px;
}
.header__button-get-report {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 36px;
  border: 2px solid var(--reflex-bluet);
  border-radius: 4px;
  text-decoration: none;
  color: var(--reflex-bluet);
}
.footer-menu-landing-wrapper .header__button-get-report {
  margin: 0 16px 16px 16px;
}
@media (max-width: 1200px) {
  .header-wrapper .gl-menu-item__label {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
