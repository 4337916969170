export default {
  history: [],
	isHash: false,
	isAddress: false,
	searchValue: null,
	searchType: '',
	canUndo: false,
	canRedo: false,
	coinType: 'btc',
	coinData: {
		key: 'btc',
	},
	currencyList: [],
	coinAmountInfo: {
		coin: null,
		decimal: null,
	},
	features: null,
}
