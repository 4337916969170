import apiService from '@/utils/api-client'
import { formatQueryStringForObject } from '@/utils/format-query'

export default {
  // eslint-disable-next-line no-unused-vars
  async getPackages({ commit }) {
    try {
      const data = await apiService.get(`packages`)
      return data
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  setCustomPackage({ commit }, { tokens = undefined, price = undefined }) {
    try {
      return apiService.post(`/packages/custom`, { tokens, price })
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  buyPackage({ commit }, { packageId, paymentMethod }) {
    try {
      return apiService.post(`payments/purchase/${packageId}`, {
        paymentMethod,
      })
    } catch (error) {
      console.error(error)
    }
  },
  buyCustomPackage(
    // eslint-disable-next-line no-unused-vars
    { commit },
    { paymentMethod, tokens = undefined, price = undefined },
  ) {
    try {
      return apiService.post(`/payments/purchase/custom`, {
        paymentMethod,
        tokens,
        price,
      })
    } catch (error) {
      console.error(error)
    }
  },

  async getPaymentHistoryList(
    // eslint-disable-next-line no-unused-vars
    { commit },
    { lazyParams: { pageSize, pageNumber, sort } },
  ) {
    try {
      const data = apiService.get(
        `/payments/history?${formatQueryStringForObject(sort, 'sort')}`,
        {
          params: { pageSize, pageNumber },
        },
      )
      return data
    } catch (error) {
      console.error(error)
    }
  },
}
