<template>
  <div class="gl-loader-spiner">
    <img
      v-if="spinDot"
      alt="loader"
      src="~@/assets/images/dot-loader.gif"
      :style="[{ width: width, 'min-width': width, 'max-width': width }]"
    >
    <div v-else-if="progressSpinner">
      <ProgressSpinner />
    </div>
    <img
      v-else
      alt="loader"
      src="../../public/assets/img/Pre.gif"
      style="height: 50px"
    >
  </div>
</template>

<script>
//Components
import ProgressSpinner from 'primevue/progressspinner'

export default {
  components: {
    ProgressSpinner,
  },
  props: {
    color: {
      type: String,
      default: '#383c54',
    },
    width: {
      type: String,
      default: '100%',
    },
    progressSpinner: {
      type: Boolean,
      default: false,
    },
    spinDot: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: var(--reflex-bluet);
  }
  40% {
    stroke: var(--reflex-bluet);
  }
  66% {
    stroke: var(--reflex-bluet);
  }
  80%,
  90% {
    stroke: var(--reflex-bluet);
  }
}
</style>
