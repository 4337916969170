export default {
  SET_SHRUNK(state, data) {
    state.isShrunk = data
  },
  SET_SHOW_LANDING_MENU(state, data) {
    state.showLandingMenu = data
  },
  SET_IS_PAYMENT_HISTORY_OPEN(state, data) {
    state.isPaymentHistoryOpen = data
  },
}
