<template>
  <div class="footer-menu-profile-select-wrapper">
    <transition name="slide">
      <div class="footer-menu-profile-select">
        <div class="flex space-between pa-3">
          <div class="fs-14 bold-600">
            Profile
          </div>
          <div
            class="pointer"
            @click="$emit('close')"
          >
            <gl-icon
              :height="12"
              name="close"
              :width="12"
            />
          </div>
        </div>

        <router-link
          v-if="isAdmin"
          class="decoration-none"
          :to="{ name: 'admin-panel' }"
        >
          <div class="footer-menu-profile-item">
            <gl-icon
              class="mr-2"
              :height="24"
              name="your-reports"
              :width="24"
            />
            <div class="">
              Admin panel
            </div>
          </div>
        </router-link>

        <router-link
          class="decoration-none"
          :to="{ name: 'profile' }"
        >
          <div class="footer-menu-profile-item">
            <gl-icon
              class="mr-2"
              :height="24"
              name="unknown-user-transparent"
              :width="24"
            />
            <div class="">
              Profile Information
            </div>
          </div>
        </router-link>

        <div
          class="footer-menu-profile-item"
          @click="contactSupport"
        >
          <gl-icon
            class="mr-2"
            :height="24"
            name="get-help-dark"
            :width="24"
          />
          <div> Get help? </div>
        </div>

        <div
          class="footer-menu-profile-item pb-4 pt-4"
          @click="logoutUser"
        >
          <gl-icon
            class="mr-2"
            :height="24"
            name="logout"
            :width="24"
          />
          <div class="">
            Log Out
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'
// Vuex
import { mapActions, mapState, mapGetters } from 'vuex'
// Utils
import { contactSatoshieyeSupport } from '@/utils/go-to-route'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlIcon,
  },
  mixins: [deviceWidthMixin],
  props: {},

  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('user', ['isAdmin']),
    userEmail() {
      return this.userData && this.userData.email
    },
  },
  watch: {
    $route() {
      this.$emit('close')
    },
  },
  methods: {
    ...mapActions('user', ['logout']),
    contactSatoshieyeSupport,
    logoutUser() {
      this.logout()
      window.location.assign('/')
    },

    contactSupport() {
      this.contactSatoshieyeSupport()
      this.$emit('close')
    },
  },
}
</script>

<style>
.footer-menu-profile-select-wrapper {
  position: absolute;
  background: rgba(9, 21, 64, 0.4);
  bottom: 0;
  left: 0;
  width: var(--full-width);
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footer-menu-wrapper-with-banner .footer-menu-profile-select-wrapper {
  bottom: calc(var(--report-banner-height) * -1);
}
.footer-menu-profile-select {
  background: var(--white);
  width: var(--full-width);
}

.footer-menu-profile-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: var(--dark);
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s linear;
}
.footer-menu-profile-item:hover {
  background: var(--cotton-grey-f-5);
  transition: all 0.2s linear;
}
.router-link-active .footer-menu-profile-item {
  background: var(--light-grey-d-b);
}
</style>
