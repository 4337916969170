<template>
  <div
    v-if="$route.name"
    class="footer-menu-wrapper"
    :class="{
      'footer-menu-wrapper-with-banner': withBanner,
    }"
  >
    <div v-if="$route.name === 'landing-page'">
      <div
        v-if="isMobile && showLandingMenu"
        class="footer-menu-landing-wrapper"
      >
        <div class="footer-menu-landing">
          <div
            class="footer-menu-landing-item"
            @click="
              scrollToSelectorItem('.main', '.landing-page-features-wrapper')
            "
          >
            Features
          </div>
          <div
            class="footer-menu-landing-item"
            @click="
              scrollToSelectorItem('.main', '.landing-page-pricing__for-background')
            "
          >
            Pricing
          </div>
          <div
            class="footer-menu-landing-item"
            @click="scrollToSelectorItem('.main', '.landing-page-faq-wrapper')"
          >
            FAQ
          </div>
          <div
            v-if="!isLogin"
            class="footer-menu-landing-item mb-4"
            @click="$router.push('/login')"
          >
            Log in
          </div>
        </div>
        <router-link
          class="header__button-get-report"
          :to="'/source-of-funds'"
        >
          Get Report
        </router-link>
      </div>
    </div>
    <div
      v-else-if="$route.name !== 'report'"
      class="footer-menu"
    >
      <div class="footer-menu-item">
        <router-link :to="{ name: 'your-reports' }">
          <gl-icon
            :height="25"
            name="your-reports-mobile"
            :width="19"
          />
        </router-link>
      </div>
      <div class="footer-menu-item">
        <router-link :to="{ name: 'source-of-funds' }">
          <gl-icon
            :height="28"
            name="search-sof"
            :width="29"
          />
        </router-link>
      </div>
      <div class="footer-menu-item">
        <router-link :to="{ name: 'payment' }">
          <gl-icon
            :height="24"
            name="payment"
            :width="24"
          />
        </router-link>
      </div>
      <div class="footer-menu-item">
        <div @click="showProfileSelect = true">
          <gl-icon
            :height="24"
            name="unknown-user-transparent"
            :width="24"
          />
        </div>
      </div>
    </div>

    <GlFooterMenuProfile
      v-show="showProfileSelect"
      @close="showProfileSelect = false"
    />
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'
import GlFooterMenuProfile from '@/components/layout/gl-footer-menu-profile'
import Button from 'primevue/button'
// Vuex
import { mapState, mapGetters, mapMutations } from 'vuex'
// Utils
import { scrollToSelector } from '@/utils/scroll-to'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    Button,
    GlIcon,
    GlFooterMenuProfile,
  },
  mixins: [deviceWidthMixin],
  props: {
    withBanner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showProfileSelect: false,
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('sidebar', ['showLandingMenu']),
    ...mapGetters('user', ['isAdmin', 'isLogin']),
    userEmail() {
      return this.userData && this.userData.email
    },
    headerHeight() {
      return getComputedStyle(document.documentElement).getPropertyValue(
        '--header-height',
      )
    },
    reportBannerHeight() {
      return getComputedStyle(document.documentElement).getPropertyValue(
        '--report-banner-height',
      )
    },
  },
  methods: {
    ...mapMutations({
      SET_SHOW_LANDING_MENU: 'sidebar/SET_SHOW_LANDING_MENU',
    }),
    scrollToSelector,
    scrollToSelectorItem(wrap, aim) {
      this.SET_SHOW_LANDING_MENU(false)
      scrollToSelector(
        wrap,
        aim,
        parseInt(this.headerHeight) + parseInt(this.reportBannerHeight),
      )
    },
  },
}
</script>
