export default {
  SET_USERS_DATA(state, data) {
    state.usersData = data
  },
  SET_PROMO_DATA(state, data) {
    state.promoData = data
  },
  SET_USERS_TOTAL(state, payload) {
    state.adminPanelUsersTotal = payload
  },
  SET_FILTER_VALUE(state, payload) {
    state.adminFilterValue = payload
  },
  SWITCH_FILTER_CLEAR_TRIGGER(state) {
    state.adminFilterValue = ''
    state.adminFilterClearTrigger = !state.adminFilterClearTrigger
  },
  SWITCH_CREATE_PROMO_MODAL_TRIGGER(state, payload) {
    state.activePromo = payload
    state.isEditMode = Boolean(state.activePromo)
    state.createNewPromoModalOpenTrigger = !state.createNewPromoModalOpenTrigger
  },
  SWITCH_TRIGGER_FOR_RELOAD_HISTORY(state) {
    state.paymentHistoryReloadTrigger = !state.paymentHistoryReloadTrigger
  },
  SWITCH_TRIGGER_FOR_REDEEM_MODAL(state) {
    state.confirmModalRedeemTrigger = !state.confirmModalRedeemTrigger
  },
  SET_AMOUNT_TOKENS(state, payload) {
    state.amountOfTokens = payload
  },
  SET_PROMO_TOTAL(state, payload) {
    state.adminPanelUsersTotal = payload
  },
}
