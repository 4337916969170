<template>
  <div
    class="report-abuse-banner"
    :class="{
      'report-abuse-banner--top': top,
    }"
  >
    <div>
      <span class="bold mr-3">Got Scammed?</span>
      <span
        class="bold-700 warning-text-red pointer"
        @click="$router.push({ name: 'report-abuse' })"
      >Report Abuse</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  inheritAttrs: false,
  props: {
    top: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style>
.report-abuse-banner {
  height: var(--report-banner-height);
  background: linear-gradient(
    90deg,
    #ffe7ed 0%,
    #ffe7ed 32.29%,
    #ffe7ed 66.67%,
    #ffe7ed 100%
  );
  position: absolute;
  bottom: 0;
  width: var(--full-width);
  z-index: 98;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-align: center;
}
.report-abuse-banner--top {
  top: 0;
  bottom: unset;
}
@media (max-width: 767px) {

  .report-abuse-banner {
    font-size: 12px;
  }
    
}
</style>
