import apiService from '@/utils/api-client'
import config from "@/utils/appConfig";

export default {
  getManifest({ commit }) {
    return apiService
      .get(`manifest`, {
        baseURL: config.VUE_APP_API_BASE_URL,
      })
      .then(({ data }) => {
        const {features, coins} = JSON.parse(data)
        commit('SET_FEATURES', features)
        return coins
      })
      .catch(err => {
        console.log(err)
      })
  },
}