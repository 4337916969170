<template>
  <div class="flex align-center justify-center">
    <div class="mr-1">
      {{ label }}
    </div>
    <template v-if="router">
      <router-link
        class="link link--router"
        :to="{ name: 'payment' }"
      >
        {{ linkText }}
      </router-link>
    </template>
    <template v-else>
      <div
        class="link"
        :class="{'bold-600': bold}"
        @click="$emit('click')"
      >
        {{ linkText }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    linkText: {
      type: String,
      default: '',
    },
    bold: {
      type: Boolean,
      default: false,
    },
    router: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style scoped>
.link--router {
  font-weight: 500;
  color: var(--dark);
}
.link.router-link-exact-active {
  color: var(--reflex-bluet);
}
</style>