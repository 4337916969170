import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import users from './modules/users'
import analytics from './modules/analytics'
import sidebar from './modules/sidebar'
import payment from './modules/payment'
import report from './modules/report'
import reportAbuse from './modules/report-abuse'
import subscribe from './modules/subscribe'
import adminPanel from './modules/admin-panel'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    users,
    sidebar,
    analytics,
    payment,
    report,
    reportAbuse,
    subscribe,
    adminPanel,
  },
})
