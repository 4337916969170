const mobileMaxWidth = 767
const tabletMaxWidth = 1023

export default {
    data() {
        return {
            windowWidth: null,
            windowHeight: null,
        }
    },
    mounted() {
      this.getWindowWidth()
      window.addEventListener("resize", this.getWindowWidth);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.getWindowWidth);
    },
    computed: {
        isMobile() {
          return this.windowWidth <= mobileMaxWidth
        },
        isTablet() {
          return this.windowWidth >= mobileMaxWidth && this.windowWidth <= tabletMaxWidth
        },
        isDesktop() {
          return this.windowWidth >= tabletMaxWidth
        },
    },
    methods: {
        getWindowWidth() {
          this.windowWidth = window.innerWidth;
          this.windowHeight = window.innerHeight;
        },
    }
}
