<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    class="redirect-to-payment-modal"
    full-buttons
    :loading="loading"
    submit-title="Go"
    title="Opps! Not enough Lookups"
    width="550"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="confirm"
  >
    <template #mainSlot>
      <div class="ma-4 break-word text-center">
        <div class="bold-600 mb-3">
          Go to the Lookups Purchase page
        </div>
      </div>
    </template>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    message: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
  },
}
</script>

<style>
.redirect-to-payment-modal .gl-modal__header-wrapper {
  justify-content: center;
}
</style>
