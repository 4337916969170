import apiService from '@/utils/api-client'
import config from "@/utils/appConfig";

export default {
  async getUsers({ commit, state }, { pageNumber, email }) {
    try {
      const params = {
        pageSize: state.adminPanelUsersPageSize,
        pageNumber,
      }
      
      if (email) {
        params.email = email;
      }
  
      const { data } = await apiService.get('users', {
        params,
        baseURL: config.VUE_APP_API_USERS_URL
      })
      
      commit('SET_USERS_DATA', data.data)
      commit('SET_USERS_TOTAL', data.pagesTotal * state.adminPanelUsersPageSize ?? null)
    } catch (err) {
      console.log(err)
    }
  },
  topUpUser({ dispatch }, { userId, balance, pageNumber }) {
    return apiService.post(
      `users/${userId}`,
      { balance },
      { baseURL: config.VUE_APP_API_USERS_URL },
    )
    .then(response => {
      if(response.success) {
        dispatch('getUsers', {pageNumber})
        dispatch('user/getMe', null, { root: true })
      }
    })
  },
  async getPromos({ state, commit }, { name }) {
    try {
      const params = {
        pageSize: 999,
        pageNumber: 1,
        'sort[updatedAt]': -1,
      }
      
      if (name) {
        params.name = name
      }

      const { data } = await apiService.get('promo-codes', {
        params,
        baseURL: config.VUE_APP_API_USERS_URL
      })
      
      commit('SET_PROMO_DATA', data.data)
      commit('SET_PROMO_TOTAL', data.pagesTotal * state.adminPanelPromoPageSize ?? null)
    } catch (err) {
      console.log(err)
    }
  },
  createPromoCode({ dispatch }, newPromo) {
    return apiService.post(`promo-codes`,
      newPromo,
      { baseURL: config.VUE_APP_API_USERS_URL },
    )
    .then(response => {
      if(response.success) {
        dispatch('getPromos', {})
      }
    })
    .catch(error => {
      console.error('There was an error creating the promo code:', error);
    })
  },
  updatePromoCode({ dispatch }, { _id, name, expiresAt, tokens, enabled }) {
    let params
    
    if(enabled) {
      params = {
        enabled,
      }
    } else {
      params = {
        name,
        expiresAt,
        tokens,
      }
    }
    return apiService.patch(`promo-codes/${_id}`,
      params,
      { baseURL: config.VUE_APP_API_USERS_URL },
    )
    .then(response => {
      if(response.success) {
        dispatch('getPromos', {})
      }
    })
    .catch(error => {
      console.error('There was an error creating the promo code:', error);
    })
  },
  redeemCode({ dispatch, commit }, promoName) {
    return apiService.post(
      `promo-codes/${promoName}`,
      { baseURL: config.VUE_APP_API_USERS_URL },
    )
    .then(response => {
      if(response.success) {
        dispatch('user/getMe', null, { root: true })

        commit('SET_AMOUNT_TOKENS', response.data.tokens)
        commit('SWITCH_TRIGGER_FOR_RELOAD_HISTORY')
        commit('SWITCH_TRIGGER_FOR_REDEEM_MODAL')
      }
    })
  },
}
