<template>
  <div
    id="app"
    :class="{ 'clear-layout': isClearHeader }"
  >
    <div
      class="app-root"
      :class="{ 'hide-header': isClearHeader }"
    >
      <gl-header
        v-if="!isClearHeader"
        :with-banner="$route.name === 'landing-page'"
      />
      <div
        id="main-container"
        class="main"
      >
        <transition
          appear
          name="scale-page"
        >
          <router-view />
        </transition>
        <Toast
          :breakpoints="{ '767px': { width: '96%', right: '2%', left: '2%' } }"
        />
        <ReportAbuseBanner
          v-if="$router.currentRoute.meta.showReportAbuseBanner"
          :top="$route.name === 'landing-page'"
        />
        <ConfirmDialog />
        <RedirectToPaymentModal
          v-if="showRedirectToPaymentModal"
          v-model="showRedirectToPaymentModal"
          @close="SET_REDIRECT_PAYMENT_MODAL(false)"
          @confirm="onRedirectToPaymentModalClick"
        />
      </div>
      <GlFooterMenu
        v-if="!isClearHeader && (isMobile || isTablet)"
        :with-banner="
          !($route.name === 'report-abuse' || $route.name === 'landing-page')
        "
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapMutations, mapState, mapActions } from 'vuex'
// Components
import GlHeader from '@/components/layout/gl-header'
import GlFooterMenu from '@/components/layout/gl-footer-menu'
import RedirectToPaymentModal from '@/pages/payment/modals/RedirectToPaymentModal'
import Toast from 'primevue/toast'
import ConfirmDialog from 'primevue/confirmdialog'
import ReportAbuseBanner from '@/pages/report-abuse/components/ReportAbuseBanner'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

import defineAbilityFor from '@/services/defineAbilityFor'

export default {
  components: {
    GlHeader,
    GlFooterMenu,
    RedirectToPaymentModal,
    Toast,
    ConfirmDialog,
    ReportAbuseBanner,
  },
  mixins: [deviceWidthMixin],
  data() {
    return {
      clearHeaderRouteNames: ['register', 'login', 'reset-password', 'confirm'],
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData', 'currencyList']),
    ...mapState('payment', ['showRedirectToPaymentModal']),
    isClearHeader() {
      return !!this.clearHeaderRouteNames.find(r => r === this.$route.name)
    },
  },
  watch: {
    $route() {
      this.SET_SHOW_LANDING_MENU(false)
    },
    coinType: {
      handler(role) {
        this.updateAbilities(role)
      },
      immediate: true,
    },
  },
  async created() {
    this.SET_COIN_CURRENCY_LIST(await this.getManifest())
    const coin = this.currencyList[0]
    this.SET_COIN_DATA(coin)
    this.SET_COIN_TYPE(coin.key)

    this.$nextTick(async () => {
      this.observeResizeMainContainer()
    })

    await this.updateAbilities(this.coinType)
  },
  methods: {
    ...mapActions('analytics', ['getManifest']),
    ...mapMutations({
      SET_SHOW_LANDING_MENU: 'sidebar/SET_SHOW_LANDING_MENU',
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA',
      SET_COIN_CURRENCY_LIST: 'analytics/SET_COIN_CURRENCY_LIST',
      SET_REDIRECT_PAYMENT_MODAL: 'payment/SET_REDIRECT_PAYMENT_MODAL',
    }),
    updateAbilities() {
      this.$ability.update(defineAbilityFor(this.coinType).rules)
    },
    onRedirectToPaymentModalClick() {
      localStorage.setItem(
        'notEnoughTokenPath',
        this.$router.currentRoute.fullPath,
      )
      this.SET_REDIRECT_PAYMENT_MODAL(false)
      this.$router.push('/payment')
    },
    async setHeaderHeight() {
      const doc = document.documentElement
      const header = document.querySelector('.header-wrapper')
      if (header) {
        doc.style.setProperty('--header-height', `${header.clientHeight}px`)
      }
    },
    async setWidth() {
      const doc = document.documentElement
      const main = document.querySelector('.main')
      if (main) {
        doc.style.setProperty('--full-width', `${main.clientWidth}px`)
      }
      setTimeout(async () => {
        this.setHeaderHeight()
      }, 1500)
    },
    documentSize() {
      const doc = document.documentElement
      doc.style.setProperty('--doc-height', `${window.innerHeight}px`)

      this.$nextTick(async () => {
        await this.setHeaderHeight()
        this.setWidth()
      })
    },
    observeResizeMainContainer() {
      const resizeObserver = new ResizeObserver(this.documentSize)
      resizeObserver.observe(document.getElementById('main-container'))
    },
  },
}
</script>

<style>
.scale-page-enter-active,
.scale-page-leave-active {
  transition: all 0.3s ease;
}

.scale-page-enter-from,
.scale-page-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.main:has(.scale-page-enter-active),
.main:has(.scale-page-leave-active) {
  height: unset !important;
}
</style>
