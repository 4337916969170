import apiService from '@/utils/api-client'

export default {
  // eslint-disable-next-line no-unused-vars
  sendReportAbuse({ commit }, { formData, recaptchaToken }) {
    try {
      return apiService.post(`/abuse/create`, formData, {
        headers: {
          'Recaptcha-Token': recaptchaToken,
        },
      })
    } catch (error) {
      console.error(error)
    }
  },
}
