import apiService from '@/utils/api-client'
import { formatQueryStringForObject } from '@/utils/format-query'

export default {
  // eslint-disable-next-line no-unused-vars
  async purchaseReports({ commit }, { address, coin, reportPartName }) {
    try {
      const data = await apiService.post(`/reports/purchase/${address}`, {
        coin,
        reportPartName,
      })
      return data
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getReportParts({ commit }, { address, coin, recalculate = undefined, token }) {
    try {
      const data = apiService.get(`reports/${address}`, {
        params: { coin, recalculate, token },
      })

      return data
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async getStartAddressCheck({ commit }, { address, coin, recalculate = undefined }) {
    try {
      const data = apiService.get(`/reports/info/${address}`, {
        params: { coin, recalculate },
      })
      return data
    } catch (error) {
      console.error(error)
    }
  },

  async getReportsHistoryList(
    // eslint-disable-next-line no-unused-vars
    { commit },
    { lazyParams: { pageSize, pageNumber, sort } },
  ) {
    try {
      const data = apiService.get(
        `/reports/purchased?${formatQueryStringForObject(sort, 'sort')}`,
        {
          params: { pageSize, pageNumber },
        },
      )
      return data
    } catch (error) {
      console.error(error)
    }
  },
}
