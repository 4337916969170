export default {
  usersData: {},
  promoData: {},
  adminPanelUsersPageSize: 10,
  adminPanelUsersTotal: 0,
  adminFilterValue: '',
  adminFilterClearTrigger: false,
  createNewPromoModalOpenTrigger: false,
  activePromo: null,
  isEditMode: false,
  paymentHistoryReloadTrigger: false,
  redeemPromoLoadingState: false,
  confirmModalRedeemTrigger: false,
  amountOfTokens: null,
  adminPanelPromoPageSize: 10,
  adminPanelPromoTotal: 0,
}
